<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'traces'"
              :total="itemsTotal"
              :fetch-path="'traces/fetch'"
              :filter-path="'traces/filter'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td class="text-end">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View trace
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'admin.traces.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="researchers"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Content</label>
                  <input
                    v-model="filters.content"
                    type="text"
                    class="form-control"
                    @change="saveFilter({ customLabel: true, name: `Content: ${$event.target.value}`, value: `${$event.target.value}` }, 'content')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Source</label>
                  <v-select
                    v-model="filters.sources"
                    multiple
                    label="name"
                    :options="sources"
                    @input="saveFilter({ customLabel: true, name: `Sources: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'sources')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All areas
                    </option>
                  </v-select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Typology</label>
                  <v-select
                    label="name"
                    :options="typologies"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'typology')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['from']"
                    format="YYYY-MM-DD"
                    class="w-100"
                    value-type="format"
                    @change="saveFilter({ customLabel: true, name: `From: ${$event}`, value: $event }, 'from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['to']"
                    format="YYYY-MM-DD"
                    class="w-100"
                    value-type="format"
                    @change="saveFilter({ customLabel: true, name: `To: ${$event}`, value: $event }, 'to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Created by</label>
                  <v-select
                    v-model="filters.created_by"
                    multiple
                    label="name"
                    :options="researchers"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Created By: ${$event ? ($event.map(e => e.first_name)).join(' - ') : ''}`, value: $event }, 'created_by')"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                  />
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Researcher<br>
                      <strong>{{ item.targetUser }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Date<br>
                      <strong>{{ item.date }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Content<br>
                      <span v-html="item.note" /></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Source<br>
                      <a
                        v-if="item.noteable_route != 'Imported'"
                        style="color: #7367f0;"
                        :href="$router.resolve({ name: item.noteable_route, params: { id: item.noteable_id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <strong>{{ item.noteable_name }}</strong>
                      </a>
                      <strong v-else>{{ item.noteable_name }}</strong>
                    </p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Created by<br>
                      <strong>{{ item.created_by }}</strong></p>
                  </div>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    title="Edit trace"
                    class="btn btn-primary mb-1 d-grid w-100"
                    :href="$router.resolve({name: 'admin.traces.edit', params: { id: item.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  >Edit</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    DatePicker,
    vSelect,
  },
  data() {
    return {
      // selectedSources: [],
      // filters: [],
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'Date',
          checked: true,
          order: 1,
        },
        {
          name: 'Researcher',
          checked: true,
          order: 2,
        },
        {
          name: 'Content',
          checked: true,
          order: 3,
        },
        {
          name: 'Source',
          checked: true,
          order: 4,
        },
        {
          name: 'Typology',
          checked: true,
          order: 5,
        },
        {
          name: 'Created',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Date: 'date',
        Researcher: 'targetUser',
        Content: 'note_for_table',
        Source: 'noteable_name',
        Created: 'created_by',
        Typology: 'typology.name',
      },
      title: 'Traces',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      items: 'traces/items',
      itemsTotal: 'traces/itemsTotal',
      researchers: 'users/fakeUsers',
      users: 'users/users',
      sources: 'traces/sources',
      filters: 'filters/filters',
      typologies: 'traceTypologies/items',
    }),
  },
  watch: {
    items() {
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
  async mounted() {
    this.$store.dispatch('traceTypologies/fetch')
    this.$store.dispatch('modals/toggleInfoItemSheet', false)
    await this.$store.dispatch('modals/fetchUserFields', 'internal_notes')
    await this.$store.dispatch('traces/filter', this.$store.getters['filters/filters'])
    await this.$store.dispatch('traces/fetchSources')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'internal_notes',
      })
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'traces', url: `${Vue.prototype.$groupUrl}/internal-notes/delete/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
  },
}
</script>
